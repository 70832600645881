
import BagTitle from './BagTitle'
import BagImage from './BagImage'
import BagChoose from './BagChoose'
import BagProps from './BagProps'
import BagCustomize from './BagCustomize'
import BagStepper from './BagStepper'
import BagGift from './BagGift'
import BagGiftChoose from './BagGiftChoose'
import BagVip from './BagVip'
import BagNew from './BagNew'
import BagEstimated from './BagEstimated'
import BagTip from './BagTip'
import BagCustomizePopup from './BagCustomizePopup'

export default {
    name: 'BagGoods',
    components: {
        BagTitle,
        BagImage,
        BagChoose,
        BagProps,
        BagCustomize,
        BagStepper,
        BagGift,
        BagGiftChoose,
        BagVip,
        BagEstimated,
        BagNew,
        BagTip,
        BagCustomizePopup
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmitParent: Object,
        edit: Object
    },
    data() {
        return {
            skeleton: true,
            hasLoading: false,
            currency: {},
            middleEmit: {},
            allInfo: {},
            goods: {
                allId: '',             // 所有id
                noInvalidId: '',       // 非失效id
                selectId: '',          // 选择的id
                selectArr: [],         // 选择的id Array
                selectLength: 0,       // 选择的id数量
                selectQuantity: 0,     // 选择的商品总数量
                hasAllSelect: false,   // 是否全选
                hasGoods: false,       // 是否含有商品
                list: []
            },
            amt: {},
            customize: {
                visible: false
            },
            params: {
                ids: '',
                onSelect: false,
                deleteId: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getBagAjax()
    },
    methods: {
        // 获取购物车数据
        getBagAjax() {
            this.$api.bag.getBag({
                id: this.params.ids,
                onSelect: this.params.onSelect
            }, this.hasLoading).then(response => {
                // goodsType -> 0 下架商品 1 普通商品 2 满赠商品 3 赠品 4 闪购商品 5 满折商品 6 礼盒 7 贺卡 8 满减 9 单品折扣 11 新人专享 12 礼品卡
                const res = response.result || {};
                this.skeleton = false
                this.hasLoading = true
                this.goods.list = res.shopCart || []
                this.amt = res.cartAmountDetail || {}
                this.allInfo = res
                this.setData()

                let addItems = []
                let index = 0
                res?.shopCart?.forEach((list)=>{
                    list.cartItems.map(cart=>{
                        addItems.push({
                            item_id: cart.skuId,
                            group_item_id: cart.productCode,
                            item_name: cart.productName,
                            quantity: cart.quantity,
                            price: (cart.skuPrice/100).toFixed(2),
                            affiliation: 'YFN',
                            item_brand: 'YFN',
                            index: index++,
                            item_variant: cart.skuCode,
                        })
                    })
                })
                this.$fnApi.buried.gtag({
                    type: 'view_cart',
                    params: {
                        currency: 'USD',
                        value:(res.cartAmountDetail.subTotalAmt/100).toFixed(2),
                        items: addItems
                    }
                })
            })
        },
        setData() {
            let allIdArr = [];
            let noInvalidIdArr = [];
            let selectIdArr = [];
            let selectQuantity = 0;
            let hasAllSelect = true;
            this.params.ids = ''
            this.params.onSelect = false
            this.goods.list.forEach(item => {
                item.cartItems.forEach(list => {
                    // 定制
                    this.setCustomizeData(list);
                    // 图片
                    list.rsImg = list.rsCustomizeImg || list.skuPic
                    list.rsDisabled = list.goodsType == 11
                    // id
                    allIdArr.push(list.id)
                    if(list.goodsType) {
                        noInvalidIdArr.push(list.id)
                        !list.onSelect && (hasAllSelect = false)
                    }
                    if(list.onSelect && list.goodsType) {
                        selectQuantity += list.quantity;
                        selectIdArr.push(list.id)
                    }
                    // 商品组件配置项
                    this.setProductData(list);
                })
            })
            this.goods.allId = allIdArr.join(',')
            this.goods.noInvalidId = noInvalidIdArr.join(',')
            this.goods.selectId = selectIdArr.join(',')
            this.goods.selectLength = selectIdArr.length
            this.goods.selectQuantity = selectQuantity
            this.goods.hasAllSelect = hasAllSelect
            this.goods.hasGoods = !!this.goods.list.length
            !this.goods.allId && (this.edit.hasEdit = false)
            this.middleEmitParent.fn('setGoodsData', {
                goods: this.goods,
                amt: this.amt,
                userLevel: this.allInfo.userLevel,
                allInfo: this.allInfo
            })
        },
        setCustomizeData(list) { // 数据设置-定制
            list.hasCustomize = list.customizeParams && list.customizeParams[0]
            list.rsFirstCustomize = {}
            if(list.hasCustomize) {
                const customizePath = list.customizePath || [];
                // list.rsFirstCustomize = list.customizeParams[0];
                list.rsCustomizeImg = customizePath[0]
                list.customizeLength = list.customizeParams.length
                // list.hasCustomizeMore = list.customizeLength > 1
                list.rsFirstCustomize = list.customizeParams;
                list.hasCustomizeMore = false
            }
        },
        setProductData(list) { // 数据设置-商品
            list.rsProduct = {
                ...list,
                title: list.productName,
                pic: list.rsImg,
                salePrice: list.skuPrice,
                orgPrice: list.skuOrgPrice,
                displaySalePrice: list.displaySkuPrice,
                displayOrgPrice: list.displaySkuOrgPrice
            }
            list.rsOptions = {
                hasCustomize: false,
                hasQuantity: false,
                hasJumpProduct: true,
                hasTitleJump: true,
                hasDiscountTag: false,
            }
            list.hasStep = !!list.goodsType
            if(!list.hasStep) {
                list.rsOptions.hasQuantity = true
            }
        },
        // 商品删除
        deleteProductAjax() {
            this.$api.bag.deleteBag({
                ids: this.params.deleteId
            }).then(response => {
                this.edit.hasEdit && (this.edit.hasEdit = false)
                this.getBagAjax()
                this.updateBagQuantity()
            })
        },
        deleteProduct() {
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to delete this item(s)')}?`,
                confirmButtonText: this.$translate('Yes'),
                cancelButtonText: this.$translate('No')
            }).then(() => {
                this.hasLoading = true
                this.deleteProductAjax()
            }).catch(() => {})
        },
        handleDelete(obj) {
            this.params.deleteId = obj.id
            this.deleteProduct()
        },
        // 商品更新
        updateProductAjax(obj) {
            this.$api.bag.setUpdateBag({
                id: obj.id,
                spuId: obj.spuId,
                skuId: obj.skuId,
                quantity: obj.quantity
            }).then(response => {
                this.hasLoading = true
                this.getBagAjax();
                this.updateBagQuantity()
            })
        },
        updateBagQuantity() { // 更新加购数量
            this.$updateBagQuantity()
        },
        setChooseProduct(obj) { // 商品选择更新
            this.params.ids = obj.id
            this.params.onSelect = obj.onSelect
            this.hasLoading = true
            this.getBagAjax();
        },
        // 定制-定制页
        showCustomize(obj, visible = true) {
            /*
            * obj: 当前商品信息
            * visible: 是否显示定制页，默认 true
            */
            const ref = this.$refs.customizePopup;
            ref && ref.showPopup(obj, visible)
        },
        // emit
        changeStepDelete(obj) { // 数量变更
            this.params.deleteId = obj.id
            this.deleteProduct()
        },
        setCusData(obj) { // 定制数据
            this.middleEmitParent.fn('setCusData', obj)
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.middleEmit.fn = (fn, options, callback) => {
                this[fn] && this[fn](options)
            }
        }
    },
}
